<template>
  <div
    class="inline-block text-xs px-3 py-1 rounded-full uppercase text-white font-bold"
    :class="iconColorClass"
  >
    <fa-icon
      :icon="itemIcon"
      class="text-white fa-fw mr-1"
    />
    {{ type }}
  </div>
</template>
<script>
import thumbmixin from '@components/assets/asset-item-thumb-mixin.js'

export default {
  name: 'ContentTypeBadge',

  mixins: [thumbmixin],

  props: ['type'],

  computed: {
    // This makes the thumb mixin behave
    asset () {
      return {
        type: this.type
      }
    }
  }
}
</script>
