export default {
  computed: {
    iconSize() {
      if (this.size === 'xs') {
        if (this.asset.type === 'folder') return 'lg'
        return 'sm'
      }

      if (this.asset.type === 'folder') return '3x'
      return 'lg'
    },

    iconSizeGrid() {
      if (this.asset.type === 'folder') return '6x'
      return '3x'
    },

    itemIcon() {
      switch (this.asset.type) {
        case 'image':
          return 'image'
        case 'video':
          return 'video'
        case 'scene':
          return 'cube'
        case 'folder':
          return 'folder'
        case 'sequence':
        case 'previz':
          return 'bars-staggered'
        case 'binary':
          return 'file-archive'
        case 'composition':
          return 'layer-group'
        case 'audio':
          return 'file-music'
        case 'upload':
          return 'file-upload'
        default: {
          switch (this.asset.file_extension) {
            case 'zip':
              return 'file-archive'
            default:
              return 'file'
          }
        }
      }
    },

    iconClass() {
      if (this.asset.type === 'folder')
        return 'text-blue-500 dark:text-blue-600'
      return 'text-white dark:text-neutral-100'
    },

    iconBaseColor() {
      switch (this.asset.type) {
        case 'image':
          return 'blue-500 dark:bg-blue-600'
        case 'video':
          return 'blue-500 dark:bg-blue-600'
        case 'scene':
          return 'orange-500 dark:bg-orange-600'
        case 'folder':
          return 'transparent'
        case 'audio':
          return 'yellow-500 dark:bg-yellow-600'
        case 'sequence':
        case 'previz':
          return 'purple-600 dark:bg-pruple-600'
        case 'binary':
          return 'gray-500 dark:bg-gray-600'
        case 'composition':
          return 'green-500 dark:bg-green-600'
        default:
          return 'gray-400 dark:bg-gray-600'
      }
    },

    iconColorClass() {
      return 'bg-' + this.iconBaseColor
    },

    iconTextColorClass() {
      if (this.asset.type === 'folder') return this.iconClass
      return 'text-' + this.iconBaseColor
    },

    sizeClasses() {
      if (this.size) {
        return 'w-8 h-8'
      }
      return 'w-10 h-10'
    }
  },

  methods: {
    /**
     * "Select asset" handler
     */
    onSelect: function (mode) {
      this.$emit('select-asset', { asset: this.asset, mode: mode })
    }
  }
}
